const headerScroll = () => {
  const main = document.querySelector("main");

  const $header = $(".header");

  if ($header) {

    // Header меняет цвета при скролле. Он уже fixed изначально
    const scrollHeader = () => {
      const introTop = main.getBoundingClientRect().top;

      if (introTop < -1) {
        $header.addClass("scroll");

      } else if ($header.hasClass("scroll") && introTop > -1) {
        $header.removeClass("scroll");
      }
    };

    $(window).on("scroll", scrollHeader);
    $(document).on("ready", scrollHeader);


    //Добавляет отступ на страницах для фиксированного хедера
    function checkHeaderHeight() {
      const value = $header.outerHeight();
      const height = $(".js-height");

      height.css("height", value);
    }
    checkHeaderHeight();

    $(window).on("resize", checkHeaderHeight);


    //Хедер при скролле наверх
    let didScroll;
    let lastScrollTop = 0;
    let delta = 5;
    let headerTop = $(".header__top").outerHeight();

    $(window).scroll(function(event){
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        if ($(window).scrollTop() > 50) {
          let st = $(this).scrollTop();

          if(Math.abs(lastScrollTop - st) <= delta)
          return;

                  //если скролл вниз
              if (st > lastScrollTop && st > headerTop){
                      $(".header__bottom").addClass('hide');

                      $(".header__top").addClass('shadow');
                      $(".header__bottom").removeClass('shadow');


                      $(".js-sub-menu").removeClass("show");
                      setTimeout(function() {
                        $(".js-sub-menu").removeClass("display");
                      }, 100);

                //если скролл наверх
              } else {
                  if(st + $(window).height() < $(document).height()) {
                    $(".header__bottom").removeClass('hide');

                    $(".header__top").removeClass('shadow');
                    $(".header__bottom").addClass('shadow');

                  }
              }

              lastScrollTop = st;
              didScroll = false;

          }else {
            $(".header__bottom").removeClass('shadow');

          }
      }
    }, 20);


    //Добавление классов при ховере на пункты меню
    const $item = $(".nav__item");

    function setWidth(el) {
      let width = $(window).width();
      el.css("width", width);
    }

    $item.each(function() {
      const $submenu = $(this).find(".js-sub-menu");

      setWidth($submenu);

      $(this).on("mouseenter", function() {
        $submenu.addClass("display");

        setTimeout(function() {
          $submenu.addClass("show");
        }, 100);
      });

      $(this).on("mouseleave", function() {
        $submenu.removeClass("show");

        setTimeout(function() {
          $submenu.removeClass("display");
        }, 100);
      });

    });

    $(window).on("resize", function () {
      setWidth($(".js-sub-menu"));
    });

  }

};

export default headerScroll;
