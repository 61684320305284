const animation = () => {
  //wow
  const animations = new window.WOW().init();

  //titles
  const $titles = $(".js-title");

  if ($titles.length) {
    //Делим заголовок побуквенно
    $(document).ready(function() {
      $titles.lettering();
    });

    //Делаем анимацию главного заголовка
    $(document).ready(function() {
      animation(".js-main-title");
    }, 500);

    //Анимация
    function animation(i) {
      if (i === ".js-main-title") {
        var id = i + " span";
      } else {
        var id ="#" + i + " span";
      }
      var title = new TimelineMax();
      title.staggerFromTo(id, 0.5,
      {ease: Back.easeOut.config(1.7), opacity: 0, bottom: 0},
      {ease: Back.easeOut.config(1.7), opacity: 1, bottom: 0}, 0.05
      );
    }

  }

  // pins
  const $mapBlock = $(".map-container");

  if ($mapBlock) {
    const $pins = $(".svg-circle");

    $mapBlock.on('inview', function(isInView) {
      if (isInView) {
        // element is now visible in the viewport
        let value = 100;

        for (let i= 0; i < $pins.length; i++) {
          value = value + 40;
          let val = value + "ms";
          $($pins[i]).css("transition-delay", val);
        }

        $pins.each(function() {
          $(this).addClass("show");
        });

      }
    });
  }

  const $tabs = $(".js-tabs-sliders");

  if ($tabs) {

    const $tabsBtns = $tabs.find(".tabs__item a");

    function clickHandler() {

      const panel = $tabs.find("#all");
      const $slides = panel.find(".swiper-slide .card");

      $slides.each(function() {
        $(this).removeClass("show");
      });

      setTimeout(function() {
        let value = 0;
        for (let i= 0; i < $slides.length; i++) {
          value = value + 100;
          let val = value + "ms";
          $($slides[i]).css("transition-delay", val);
        }

        $slides.each(function() {
          $(this).addClass("show");
        });
      }, 200);

    }
    clickHandler();

    $tabsBtns.on("click", function() {
      let href = $(this).attr('href');

      const panel = $tabs.find(href);
      const $slides = panel.find(".swiper-slide .card");

      $slides.each(function() {
        $(this).removeClass("show");
      });

      setTimeout(function() {
        let value = 100;
        for (let i= 0; i < $slides.length; i++) {
          value = value + 100;
          let val = value + "ms";
          $($slides[i]).css("transition-delay", val);
        }

        $slides.each(function() {
          $(this).addClass("show");
        });
      }, 200);

    });

  }

};

export default animation;
