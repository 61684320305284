import nodeListForEach from './node-list-for-each';
import tel from './tel';
import animation from './animation';
import menuOpen from './menu-open';
import modal from './modal';
import headerScroll from './header';
import sliders from './sliders';
import clumpString from './clump';
import sort from './sort';
import number from './number';
import filter from './filter-open';
import numeral from './numeral';
import btnUp from './btn-up';
import tabs from './tabs';
import formBlock from './form';
import accordion from './accordion';
import youtubePopup from './youtube-popup';

class App {
  static init() {
    nodeListForEach();
    tel();
    animation();
    menuOpen();
    headerScroll();
    modal();
    sliders();
    sort();
    number();
    filter();
    numeral();
    clumpString();
    youtubePopup();
    btnUp();
    tabs();
    formBlock();
    accordion();
  }
}


App.init();
window.App = App;
