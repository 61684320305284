const sliders = () => {
  const Swiper = window.Swiper;

  // Slider intro
  const intro = document.querySelector(".js-intro-slider");

  if (intro) {
    const mySwiper = new Swiper(".js-intro-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      fadeEffect: {
        crossFade: true
      },
      effect: "fade",
      navigation: {
        nextEl: ".js-intro-slider .swiper-button-next",
        prevEl: ".js-intro-slider .swiper-button-prev",
      },
      pagination: {
        el: ".js-intro-slider .swiper-pagination",
        clickable: true,
      },
    });

    // Определяем и меняем картинку в слайдере
    const imageContainer = intro.querySelector(".js-image");
    const textContainer = intro.querySelector(".js-text");
    function sliderImageChange() {
      let activeSlide = intro.querySelector(".swiper-slide-active");

      if (activeSlide) {
        let image = activeSlide.getAttribute("data-img");

        imageContainer.style.cssText = "display: block;";
        textContainer.style.cssText = "display: block;";

        setTimeout(function() {
          imageContainer.style.cssText = image;
          imageContainer.classList.add("show");

          textContainer.classList.add("show");
        }, 400);

      }
    }
    sliderImageChange();

    mySwiper.on('transitionStart', function() {
      if (imageContainer.classList.contains("show")) {
        imageContainer.classList.remove("show");
        imageContainer.style.cssText = "display: none;";

        textContainer.classList.remove("show");
        textContainer.style.cssText = "display: none;";

      }
      sliderImageChange();
    });
  }


  // Slider people
  const people = document.querySelector(".js-people-slider");

  if (people) {
    const mySwiper = new Swiper(".js-people-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 400,
      fadeEffect: {
        crossFade: true
      },
      effect: "fade",
      navigation: {
        nextEl: ".js-people-slider .swiper-button-next",
        prevEl: ".js-people-slider .swiper-button-prev",
      },
    });

    // Определяем и меняем картинку в слайдере
    const imageContainer = people.querySelector(".js-image");
    const textContainer = people.querySelector(".js-text");
    function sliderImageChange() {
      let activeSlide = people.querySelector(".swiper-slide-active");

      if (activeSlide) {
        let image = activeSlide.getAttribute("data-img");

        imageContainer.style.cssText = "display: block;";
        textContainer.style.cssText = "display: block;";

        setTimeout(function() {
          imageContainer.style.cssText = image;
          imageContainer.classList.add("show");

          textContainer.classList.add("show");
        }, 400);

      }
    }
    sliderImageChange();

    mySwiper.on('transitionStart', function() {
      if (imageContainer.classList.contains("show")) {
        imageContainer.classList.remove("show");
        imageContainer.style.cssText = "display: none;";

        textContainer.classList.remove("show");
        textContainer.style.cssText = "display: none;";

      }
      sliderImageChange();
    });

  }

};

export default sliders;
