const clumpString = () => {
  //код будет работать везде, кроме ie благодаря условию ниже
  let ua = window.navigator.userAgent;
  let msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return;
  } else {

    $('.js-clump-3').each(function(index, element) {
      $clamp(element, { clamp: 3 });
    });

    $('.js-clump-2').each(function(index, element) {
      $clamp(element, { clamp: 2 });
    });
  }

};

export default clumpString;
