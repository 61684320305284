const youtubePopup = () => {
  // Видео в модальном окне
  const video = document.querySelector(".js-video");

  if (video) {
    $(".js-video").grtyoutube();
  }

};

export default youtubePopup;
