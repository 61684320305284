const sort = () => {

  //Active state for catalog"s categories
  const $linksContainer = $(".js-active-state");

  if ($linksContainer) {
    const links = $(".js-active-state a");

    links.each(function() {
      const $this = $(this);

      $this.on("click", function() {
        links.each(function() {
          $(this).removeClass("active");
        });

        if ($this.hasClass("active")) {
          $this.removeClass("active");
        } else {
          $this.addClass("active");
        }

      });
    });
  }

  const $viewLinks = $(".link-view");

  if ($viewLinks) {

    $viewLinks.on("click", function(evt) {
      evt.preventDefault();
      if ($(this).hasClass("active")) {}
      else {
        $viewLinks.toggleClass("active");
        $(".js-catalog-content").toggleClass("catalog-content--row");
      }
    })

    function removeRow() {
      if ($(window).width() < 992) {
        $(".catalog-list").removeClass("catalog-content--row");
          $(".icon-row").removeClass("active");
          $(".icon-column").addClass("active");
      }
    }

    $(window).on("resize", function(){
      removeRow();
  });
  }


  const $sortLink = $(".js-sort-link");

  if ($sortLink) {
    $sortLink.on("click", function (evt) {
      evt.preventDefault();

      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(this).blur();
      } else {
        $(this).addClass("active");
        $(this).blur();
      }
    });
  }

};

export default sort;
