const formBlock = () => {
  const formBlocks = document.querySelectorAll(".fieldset");

  if (formBlocks.length) {
    formBlocks.forEach(function(fieldset) {
      const span = fieldset.querySelector(".label-span");

      if (span) {
        const input = fieldset.querySelector("input, textarea");

        input.addEventListener("input", function () {
          if (input.value !== "") {
            span.classList.add("focus");
          } else {
            span.classList.remove("focus");
          }
        });

      }
    });

  }
};

export default formBlock;
