const menuOpen = () => {
  // Открытие моб меню
  const $buttonsMenu = $(".header__menu-btn");
  $(".header").css("z-index", "8");

  if ($buttonsMenu.length) {
    const $menu = $(".menu");
    const $buttonClose = $(".js-btn-close");

    $buttonsMenu.each(function () {
      const $btn = $(this);
      $btn.click(function() {

        // если открыто меню
        if ($menu.hasClass("is-show")) {

          const pos = parseInt($("body").attr("data-scroll"), 10);
          $(".header").css("z-index", "8");
          $menu.removeClass("is-show");
          $btn.removeClass("is-show");

          $("body").removeClass("is-menu-open").removeAttr("data-scroll");
          window.scrollTo(0, pos);

          // если закрыто меню
        } else {

          $menu.addClass("is-show");
          $(".header").css("z-index", "");

          setTimeout(function () {
            $btn.addClass("is-show");
          }, 300);

          setTimeout(function () {
            const pagePos = $(window).scrollTop();
            $("body").addClass("is-menu-open").attr("data-scroll", pagePos);
          }, 800);

        }
      });
    });

    $buttonClose.click(function () {
      const pos = parseInt($("body").attr("data-scroll"), 10);
      $(".header").css("z-index", "8");
      $menu.removeClass("is-show");
      $buttonsMenu.each(function () {
        const $btn = $(this);
        $btn.removeClass("is-show");
      });

      $("body").removeClass("is-menu-open").removeAttr("data-scroll");
      window.scrollTo(0, pos);
    });

    const currentHeaderState = () => {
      $buttonsMenu.each(function () {
        const $btn = $(this);
        $btn.css("z-index", "1");
      });
      $buttonClose.addClass("show");
    };
    currentHeaderState();

    $(window).on("scroll", function() {
      currentHeaderState();
    });

  }

};

export default menuOpen;
